<template>
    <div class="c-image-module">
        <Component
            :is="setComponentTag"
            v-if="hasImage"
            v-bind="componentProps"
            class="c-image-module__img-wrapper"
        >
            <BaseImage
                :sources="sources"
                :defaultURL="defaultImageUrl"
                :imgClass="imageClasses"
                :title="backgroundImage.description"
            />
            <slot></slot>
        </Component>
        <EditorialImageText
            v-if="editorialText"
            :editorialText="editorialText"
            :numberOfModules="numberOfModules"
            :buttons="textButtons"
            :disablePointerEvents="disablePointerEvents"
        />
        <CTAButtons
            v-if="freeButtons"
            :buttons="freeButtons"
            :numberOfModules="numberOfModules"
            :isAbsoluteCentered="freeButtons.ctaPosition === 'center'"
        />
    </div>
</template>

<script>

import { get } from 'lodash-es';

import { DEFAULT_IMAGE_QUALITY } from '~coreModules/contentful/js/contentful-constants';

import EditorialImageText from '~coreModules/contentful/components/EditorialImageText.vue';
import CTAButtons from '~coreModules/contentful/components/CTAButtons.vue';
import BaseImage from '~coreModules/core/components/ui/BaseImage.vue';

export default {
    name: 'ImageModule',
    components: {
        BaseImage,
        CTAButtons,
        EditorialImageText,
    },
    props: {
        backgroundImage: {
            type: Object,
            default: () => ({
                smallImage: {},
            }),
        },
        buttons: {
            type: Object,
            default: null,
        },
        destination: {
            type: Object,
            default: null,
        },
        editorialText: {
            type: Object,
            default: null,
        },
        numberOfModules: {
            type: Number,
            default: null,
        },
        stretchImageToContainer: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasImage() {
            return !!(get(this.backgroundImage, 'smallImage') || get(this.backgroundImage, 'largeImage'));
        },
        defaultImageUrl() {
            return this.getImageSrc('smallImage');
        },
        textButtons() {
            if (!this.editorialText || (this.buttons && this.buttons.ctaPosition === 'bottom')) {
                return null;
            }

            return this.buttons;
        },
        freeButtons() {
            if (this.editorialText && this.buttons && this.buttons.ctaPosition !== 'bottom') {
                return null;
            }

            return this.buttons;
        },
        imageClasses() {
            const isEditorialTextNotCentered = !!this.editorialText && (this.editorialText.textPosition !== 'center');

            return ({
                'c-image-module__img': true,
                'c-image-module__img--with-spacer': isEditorialTextNotCentered,
                'c-image-module__img--stretch': this.stretchImageToContainer,
            });
        },
        setComponentTag() {
            return this.destination ? 'BaseLink' : 'div';
        },
        componentProps() {
            if (!this.destination) {
                return {};
            }
            return {
                to: this.destination.linkPath,
                target: this.destination.openInNewWindow ? '_blank' : '_self',
            };
        },
        disablePointerEvents() {
            return !!this.destination;
        },
        sources() {
            const sources = [{
                srcset: this.getImageSrc('largeImage', 'webp'),
                media: '(min-width: 768px)',
                type: 'image/webp',
                valid: true,
            }, {
                srcset: this.getImageSrc('largeImage', 'jpg', 'progressive'),
                media: '(min-width: 768px)',
                type: 'image/jpeg',
                valid: this.isContentTypeValid('largeImage', 'image/jpeg'),
            }, {
                srcset: this.getImageSrc('largeImage'),
                media: '(min-width: 768px)',
                type: null,
                valid: true,
            }, {
                srcset: this.getImageSrc('smallImage', 'webp'),
                media: null,
                type: 'image/webp',
                valid: true,
            }, {
                srcset: this.getImageSrc('smallImage', 'jpg', 'progressive'),
                media: null,
                type: 'image/jpeg',
                valid: this.isContentTypeValid('smallImage', 'image/jpeg'),
            }];

            return sources.filter(source => source.valid && source.srcset);
        },
    },
    methods: {
        getImageSrc(imgType, format, flag) {
            const url = get(this.backgroundImage, `${imgType}.url`);
            const quality = get(this.backgroundImage, 'imageQuality', DEFAULT_IMAGE_QUALITY);
            const formatParam = format ? `&fm=${format}` : '';
            const flagParam = flag ? `&fl=${flag}` : '';

            if (url) {
                return url.indexOf('?') !== -1 ? url : `${url}?q=${quality}${formatParam}${flagParam}`;
            }

            return null;
        },
        isContentTypeValid(imgType, contentType) {
            const entryContentType = get(this.backgroundImage, `${imgType}.contentType`);
            return contentType === entryContentType;
        },
    },
};
</script>

<style lang="scss">
    .c-image-module {
        position: relative;
        height: 100%;

        > a {
            display: block;
        }

        &__img-wrapper {
            position: relative;
        }

        &__img {
            display: block;
            width: 100%;

            &--with-spacer {
                margin-bottom: $nu-spacer-2;
            }

            &--stretch {
                object-fit: cover;
                height: 100%;
            }
        }
    }
</style>
