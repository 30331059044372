<template>
    <picture>
        <source
            v-for="(source, index) in sources"
            :key="index"
            :srcset="source.srcset"
            :media="source.media"
            :type="source.type"
        >
        <img
            :src="defaultURL"
            :alt="title"
            :class="imgClass"
            @load="$emit('load')"
        >
    </picture>
</template>

<script>
export default {
    name: 'BaseImage',
    props: {
        sources: {
            type: Array,
            default: null,
        },
        defaultURL: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: '',
        },
        imgClass: {
            type: [String, Object],
            default: null,
        },
    },
    emits: ['load'],
};
</script>
