<template>
    <div :class="containerClasses">
        <div :class="rowClasses" :style="textStyle">
            <label
                v-if="editorialText.label"
                :class="getSpacerClass('label', '', 'u-spacer--0pt5')"
            >
                {{ editorialText.label }}
            </label>
            <Component
                :is="headlineTag"
                v-if="editorialText.headline"
                :class="headlineClass"
            >
                {{ editorialText.headline }}
            </Component>
            <h5
                v-if="editorialText.subheadline"
                :class="getSpacerClass('subheadline', 'u-spacer--1')"
            >
                {{ editorialText.subheadline }}
            </h5>
            <div v-if="editorialText.body" :class="bodyClass">
                <BaseMarkdown :source="editorialText.body" />
            </div>
        </div>
        <CTAButtons v-if="buttons" :class="rowClasses" :buttons="buttons" />
    </div>
</template>

<script>

import { get, isEmpty } from 'lodash-es';

import { formatHexColor } from '~coreModules/core/js/utils';

import {
    colorMap,
    EDITORIAL_TEXT_BODY_STYLES,
    EDITORIAL_TEXT_POSITIONS,
} from '~coreModules/contentful/js/contentful-constants';

import BaseMarkdown from '~coreModules/core/components/ui/BaseMarkdown.vue';
import CTAButtons from '~coreModules/contentful/components/CTAButtons.vue';

export default {
    name: 'EditorialImageText',
    components: {
        BaseMarkdown,
        CTAButtons,
    },
    props: {
        editorialText: {
            type: Object,
            required: true,
        },
        numberOfModules: {
            type: Number,
            default: null,
        },
        buttons: {
            type: Object,
            default: null,
        },
        applyCenterClass: {
            type: Boolean,
            default: true,
        },
        disablePointerEvents: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        containerClasses() {
            const {
                center,
                below,
                top,
                bottom,
            } = EDITORIAL_TEXT_POSITIONS;

            const textIsCenter = this.editorialText.textPosition === center;
            const textIsBelow = this.editorialText.textPosition === below;
            const textIsTop = this.editorialText.textPosition === top;
            const textIsBottom = this.editorialText.textPosition === bottom;

            let rowStyles = textIsBelow ?
                'o-row--nested' :
                `c-editorial-image-text--${this.numberOfModules}-up`;

            if ((isEmpty(this.buttons) && !textIsBelow) || this.disablePointerEvents) {
                rowStyles += ' c-editorial-image-text--not-clickable';
            }

            if (this.applyCenterClass && textIsCenter) {
                rowStyles += ' c-editorial-image-text--center';
            }

            if (textIsTop) {
                rowStyles += ' c-editorial-image-text--top';
            }

            if (textIsBottom) {
                rowStyles += ' c-editorial-image-text--bottom';
            }

            return `c-editorial-image-text ${rowStyles}`;
        },
        rowClasses() {
            const textIsBelow = this.editorialText.textPosition === EDITORIAL_TEXT_POSITIONS.below;
            if (!textIsBelow) {
                return null;
            }

            return ({
                'o-extra-small--12': true,
                'o-medium--6': this.numberOfModules === 1,
            });
        },
        textStyle() {
            const { textAlignment, textColor } = this.editorialText;

            const color = formatHexColor(textColor) || colorMap.get(textColor);

            return {
                ...(textColor && { color }),
                textAlign: textAlignment,
            };
        },
        headlineClass() {
            const titleStyle = this.editorialText.headlineStyle || 'title-1';
            const spacerMap = new Map([
                ['title-1', 'u-spacer--1pt5'],
                ['title-2', 'u-spacer--1'],
                ['title-3', 'u-spacer--1pt5'],
                ['title-4', 'u-spacer--1'],
                ['heading-1', 'u-spacer--1'],
                ['heading-2', 'u-spacer--0pt5'],
                ['heading-3', 'u-spacer--0pt5'],
                ['heading-4', 'u-spacer--0pt5'],
                ['heading-5', 'u-spacer--0pt5'],
                ['heading-6', 'u-spacer--0pt5'],
            ]);
            const headlineSpacer = spacerMap.get(titleStyle);
            const baseClass = `o-text--${titleStyle}`;

            return this.getSpacerClass('headline', baseClass, headlineSpacer);
        },
        bodyClass() {
            const ctaType = get(this.buttons, 'ctaStyle', '').split('-')[0];
            const spacerClass = ctaType === 'link' ? 'u-spacer--3' : 'u-spacer--4';
            const bodyIsCaption = get(this.editorialText, 'bodyStyle', '') ===
                EDITORIAL_TEXT_BODY_STYLES.caption ? 'o-text--caption' : '';

            return this.getSpacerClass('body', bodyIsCaption, spacerClass);
        },
        headlineTag() {
            return this.editorialText.tagHeadlineAsH1 ? 'h1' : 'div';
        },
    },
    methods: {
        getSpacerClass(currentItem, baseClass, spacerClass) {
            const reverseItemOrder = ['buttons', 'body', 'subheadline', 'headline', 'label'];
            const actualItems = [
                this.buttons,
                this.editorialText.body,
                this.editorialText.subheadline,
                this.editorialText.headline,
                this.editorialText.label,
            ];

            const currentItemIndex = reverseItemOrder.indexOf(currentItem);
            actualItems.splice(currentItemIndex);

            // add spacer class if at least one item below the target is present
            const itemsBelowPresent = actualItems.filter(item => !!item);
            return itemsBelowPresent.length > 0 ? `${baseClass} ${spacerClass}`.trim() : baseClass;
        },
    },
};
</script>

<style lang="scss">
    .c-editorial-image-text {

        &--center,
        &--top,
        &--bottom {
            position: absolute;
            width: 100%;
        }

        &--center {
            top: 50%;
            transform: translateY(-50%);
        }

        &--top {
            top: 10%;
            transform: translateY(-10%);
        }

        &--bottom {
            top: 90%;
            transform: translateY(-90%);
        }

        &--not-clickable {
            pointer-events: none;
        }

        &--1-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 20%;
            }
        }

        &--2-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 16%;
            }
        }

        &--3-up {
            padding: 0 12%;
        }

        &--4-up {
            padding: 0 8%;
        }
    }
</style>
