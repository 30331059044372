<i18n>
[
    "global__supportEmailAddress--escaped"
]
</i18n>

<template>
    <div :class="containerClasses">
        <template v-for="button in buttons.destinations">
            <div
                v-if="button.shouldOpenChat"
                :key="`intercom-link-${button.linkPath}`"
                class="c-cta-buttons__button-container"
                @click="openIntercom"
            >
                <BaseButton
                    :colorVariant="colorVariant"
                    :size="buttons.buttonSize"
                    :variant="buttonVariant"
                    class="c-cta-buttons__button"
                    :class="{
                        [`c-cta-buttons__button--${buttonVariant}-${colorVariant}`]: true,
                    }"
                >
                    {{ button.linkTitle }}
                </BaseButton>
            </div>
            <div
                v-else
                :key="button.linkPath"
                class="c-cta-buttons__button-container"
            >
                <BaseLink
                    :to="button.linkPath"
                    :target="button.openInNewWindow ? '_blank' : '_self'"
                    :isButton="true"
                    :colorVariant="colorVariant"
                    :size="buttons.buttonSize"
                    :variant="buttonVariant"
                    class="c-cta-buttons__button"
                >
                    {{ button.linkTitle }}
                </BaseLink>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { COMPLIANCE_MODULE_NAME } from '~coreModules/compliance/js/compliance-store';
import BaseButton from '~coreModules/core/components/ui/buttons/BaseButton.vue';

export default {
    name: 'CTAButtons',
    components: {
        BaseButton,
    },
    props: {
        buttons: {
            type: Object,
            default: null,
        },
        isAbsoluteCentered: {
            type: Boolean,
            default: false,
        },
        numberOfModules: {
            type: Number,
            default: null,
        },
    },
    computed: {
        ...mapGetters(COMPLIANCE_MODULE_NAME, [
            'shouldTrack',
        ]),
        containerClasses() {
            const { ctaAlignment, ctaPosition } = this.buttons;
            const isCentered = ctaPosition === 'center';
            const isBottom = ctaPosition === 'bottom';

            return ({
                'c-cta-buttons': true,
                'c-cta-buttons--centered': isBottom || (isCentered && !this.isAbsoluteCentered),
                'c-cta-buttons--centered-absolute': isCentered && this.isAbsoluteCentered,
                'c-cta-buttons--bottom': isBottom,
                [`c-cta-buttons--vertically-${ctaAlignment}`]: true,
                [`c-cta-buttons--${this.numberOfModules}-up`]: !!this.numberOfModules && ctaPosition !== 'below',
            });
        },
        buttonVariant() {
            const { ctaStyle = '' } = this.buttons;
            const styleParts = ctaStyle.split('-');
            return styleParts[0];
        },
        colorVariant() {
            const { ctaStyle = '' } = this.buttons;
            const styleParts = ctaStyle.split('-');
            return styleParts[1];
        },
    },
    methods: {
        openIntercom() {
            if (this.shouldTrack) {
                window.Intercom('show');
            } else {
                window.location.href = `mailto:${this.$t('global__supportEmailAddress--escaped')}`;
            }
        },
    },
};
</script>

<style lang="scss">
    .c-cta-buttons {
        $this: &;

        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: none;

        @include breakpoint(medium) {
            display: block;
        }

        &--centered {
            display: flex;
            justify-content: center;
        }

        &--centered-absolute {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &--bottom {
            position: absolute;
            bottom: $nu-spacer-3;
            width: 100%;
        }

        &--vertically-center {
            align-items: center;
            text-align: center;
        }

        &--vertically-right {
            text-align: right;
        }

        &__button-container {
            margin-bottom: $nu-spacer-2;

            &:last-child {
                margin-bottom: 0;
            }

            @include breakpoint(medium) {
                margin-bottom: 0;
                margin-right: $nu-spacer-4;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &__button {
            pointer-events: auto;
            &--primary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--primary-light {
                &:hover,
                &:focus,
                &:active {
                    border-color: $nu-white !important;
                }
            }

            &--secondary-dark {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                }
            }

            &--secondary-light {
                &:hover,
                &:focus,
                &:active {
                    background-color: $nu-white !important;
                    border-color: $nu-primary !important;
                    color: $nu-primary !important;
                }
            }

            &--link-dark,
            &--link-light {
                height: auto;
                padding: 0;
            }
        }

        &--1-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 20%;
            }
        }

        &--2-up {
            padding: 0 8%;

            @include breakpoint(medium) {
                padding: 0 16%;
            }
        }

        &--3-up {
            padding: 0 12%;
        }

        &--4-up {
            padding: 0 8%;
        }
    }
</style>
